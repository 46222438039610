.cont-service-title {
    background-color: #FFFFFF;
    border: 1px solid #E0E1E2;
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    height: 67px;
    padding: 0 1.5em;
    display: flex;
    align-items: center;
    margin: 0 0 1em 0;
}

.cont-client-name {
    background-color: rgba(176, 185, 201, 0.17);
    height: 48px;
    color: #35CC62;
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 1.5em;
}

.cont-table {
    background-color: #FFFFFF;
    padding: 1em 2em;
}

.cont-page {
    display: flex;
    align-items: center;
    margin-top: 1em;
}

.cont-numbers {
    display: flex;
    gap: 15px;
    margin: 0 15px;
}

.numbers {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    border: 2px solid #E6EAF1;
    color: #B0B9C9;
    font-family: Muli;
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
}

.numbers-selected {
    color: #FFFFFF;
    background-color: #35CC62;
    border: 2px solid #35CC62;
}



.headers {
    color: #333333;
    font-family: Muli;
    font-size: 14px;
    font-weight: 600;
    width: 10em;
}

.content {
    color: #838D9E;
    font-family: Muli;
    font-size: 14px;
    width: 10em;
}

.grid {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 1em 1em;
    padding: 10px 2em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: max-content;
}

.table-container {
    margin: 1em;
    overflow-x: auto;
}

.divisor {
    margin: 0;
    width: 100% !important;
}

.cont-arrows {
    border: 2px solid #e6eaf1;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

//Mobile
.cont-client-name-sm {
    background-color: rgba(86, 146, 255, 0.11);
    height: 42px;
    color: #5692FF;
    font-family: Muli;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 0 1em;
    margin-top: 1em;
}

.card-sm {
    background-color: #FFFFFF;
    // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1em;
    gap: 1em 2em;
}

.titles-sm {
    color: #838D9E;
    font-family: Muli;
    font-size: 11px;
    font-weight: 600;
}

.text-sm {
    color: #333333;
    font-family: Muli;
    font-size: 12px;
}

@media (max-width: 600px) {


    .cont-page {
        justify-content: center;
    }

    .cont-service-title {
        margin: 0;
    }
}