@media only screen and (max-width: 768px) {
  //.app-container,
  .data-grid-container,
  .page-content {
    padding: 0px !important;
  }
  .table-layout {
    margin: 0px !important;
  }
  .header-container {
    width: auto !important;
  }
  .input-box {
    position: relative;

    .icon {
      position: absolute;
      top: 35px;
      left: 400px;
      font-size: 20px;
    }

    .calendar-icon {
      background: white url("/images/calendar-grey.svg") right no-repeat;
      padding-right: 17px;
      left: 10px;
      width: 30px;
      background-origin: content-box;
    }

    .box {
      box-sizing: border-box;
      height: 32px;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      background-color: #ffffff;
      margin-top: 0px;
      text-indent: 10px;
      margin-bottom: 20px !important;
      box-shadow: none !important;
    }

    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container {
        margin-left: -10px;
        margin-right: 10px;
        //margin-top: 5px;

        .box text-box {
          box-sizing: border-box;
          height: 50px;
          width: 100%;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          background-color: #ffffff;
          box-shadow: none !important;
          background: white url("/images/calendar-grey.svg") right no-repeat;
          padding-right: 17px;
          left: 10px;
          width: 30px;
          background-origin: content-box;
        }
      }
    }
  }
}

.list-header-component {
  margin-top: 10px;
}

.visit-card {
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  .notes-content {
    //height: 100px;
    color: #333333;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.abandoned-status {
  background-color: #b0b9c9;
  color: #5b6576;
  height: 33px;
  padding: 7px;
  margin: 5px;
}

.start-status {
  background-color: #030d35;
  color: #5b6576;
  height: 33px;
  padding: 7px;
  margin: 5px;
}

.mobile-device-status {
  background-color: transparent;
  color: #5b6576;
  height: 33px;
  padding: 7px;
  margin: 5px;
}

.progress-status {
  background-color: #d05317ad;
  color: #d05317;
  height: 33px;
  padding: 7px;
  margin: 5px;
}

.completed-status {
  background-color: #d05317ad;
  color: #35cc62;
  height: 33px;
  padding: 7px;
  margin: 5px;
}

.value {
  color: #333333;
  font-size: 16px;
  margin-bottom: 10px;
}

.clock-in {
  color: #263859;
  font-size: 16px;

  .time {
    font-size: 22px;
  }
}

.abandon-visit {
  .alert-circle {
    margin-right: 10px;
    vertical-align: sub;
  }

  .abandon-header {
    font-size: 21px;
    font-weight: bold;
    color: #333333;
  }

  .abandon-date-time {
    background: #b0b9c9;
    border-radius: 6px;
    width: 100%;
    height: 72px;
    padding: 26px;

    .date-text {
      color: #27395a;
      font-weight: bold;
      font-size: 16px;
    }

    .calendar {
      margin-right: 10px;
      vertical-align: sub;
    }
  }
}

.visit-notes {
  color: #27395a;
  font-size: 14px;
}

.notes-text-area {
  border: solid rgba(0, 0, 0, 0.1);
  border-radius: 4%;
  height: 100px;
  margin-bottom: 20px;
  width: 100%;
}

.in-progress-banner {
  background: #27395a;
  color: #ffffff;
  font-size: 14px;
  width: 100%;
  font-weight: bold;
  height: 50px;
  padding: 12px;

  :hover {
    cursor: pointer;
  }

  .duration {
    font-size: 17px;
    font-weight: bolder;
  }
}

.table-layout {
  margin: 30px;

  .header-container {
    padding: 20px;

    .title {
      min-height: 42px;
      padding: 10px;
      color: #27395a;
      font-family: Muli;
      font-size: 28px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 42px;
      margin-top: 30px;
      margin-bottom: 24px;
      min-width: 103px;
    }

    .sub-title {
      height: 17px;
      padding: 20px;
      color: #333333;
      font-family: "Muli";
      //font-family: ".SF NS Display";
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 17px;
      text-align: right;
    }

    @media only screen and (max-width: 768px) {
      .sub-title {
        text-align: left;
      }
    }
  }

  .body-container {
    background-color: #fff;
    .body-line {
      box-sizing: border-box;
      height: 1px;
      margin-left: 4%;
      margin-right: 4%;
    }

    .list-container {
      padding: 20px;
      margin-bottom: 0px;

      .list-header {
        height: 29px;
        color: #333333;
        font-family: Muli;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 29px;
        margin-bottom: 10px;
      }

      .list-dropdown {
        height: 32px;
        color: #b0b9c9;
        font-family: "PT Sans";
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 19px;
        text-align-last: center;
      }
    }

    .data-grid-container {
      padding: 20px;
      .css-1smlk3z-MuiPaper-root {
        border-radius: 20px;
        background-color: #35cc62;
      }
      .custom-cal {
        border-radius: 20px;
      }
      .rmdp-range {
        background-color: #b0b9c94d;
        color: #030d35;
        .start {
          background-color: blue;
        }
      }
      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          margin-top: 0px;
        }
      }
      .label {
        align-self: baseline;
        margin-right: 0px;
        height: 18px;
        width: 32px;
        color: #333333;
        font-family: Muli;
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 18px;
        //   text-align: center;
      }
      .pay-label {
        height: 66px;
        width: 100%;
        border-radius: 8px;
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: rgba(86, 146, 255, 0.13);
      }
      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        color: #5692ff;
        font-family: "PT Sans";
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
  }
}

.last-edit {
  color: #b0b9c9;
  font-family: Muli;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
}

.visit-notes-details {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 9px;
  background-color: #f6f8fa;

  .label {
    color: rgba(38, 56, 89, 0.42);
    font-family: Muli;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 29px;
    margin-left: 10px;
    vertical-align: center;
  }
}

.notes-content {
  padding: 10px;

  .label {
    height: 17px;
    width: 38px;
    color: #27395a;
    font-family: ".SF NS Display";
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: 17px;
    margin-bottom: 10px;
  }

  .hidden {
    display: none;
  }

  .notes-text {
    color: #333333;
    font-family: Muli;
    font-size: 15px;
    letter-spacing: 0;
    line-height: 29px;
  }

  .notes-text-disabled {
    color: rgb(176, 185, 201);
    font-size: 12px;
    font-style: italic;
  }

  .notes {
    box-sizing: border-box;
    height: 123px;
    width: 100%;
    border: 2px solid #35cc62;
    border-radius: 4px;
    background-color: #ffffff;
  }
}

.header-component {
  padding: 5px;

  .searchable-dropdown-container {
    .action-button {
      top: 15px !important;
    }
  }
}

.progress-selection {
  height: 30px;
}
